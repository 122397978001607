<template>
  <div>
    <px-capa :banner-capa="header"
             disable-link
             x-large
             @click="onClickCapaBtn"
             btn-text="Quero fazer uma cotação agora">
      <template v-slot:title>
        Compre plástico filme stretch para sua empresa com a Proxpect
      </template>
      <template v-slot:text>
        Entre em contato agora e cote seu pedido
      </template>
    </px-capa>
    <v-container class="mkt-content-container mt-5">
      <px-home-page-block
        show-link
        link="/q/c/204"
        link-target="_blank"
        class="mt-5"
        title="Conheça nossos produtos"
        :show-price="false"
        :query="query"
        :qtd-ofertas="20"/>
      <px-landing-page-whatsapp class="mt-5 mb-12" phone-number="5548992120020"/>
      <px-landing-page-banners class="mt-12"
                               :banners="banners"/>
      <div id="container-form-cotacao" class="headline font-weight-bold mt-12 ml-2 mb-5">
        Que tal fazer uma cotação agora?
      </div>
      <v-row class="mt-10">
        <v-col cols="12">
          <px-rd-duvida-form-subscription/>
        </v-col>
      </v-row>
      <px-home-page-block
        show-link
        link="/q/d/23"
        link-target="_blank"
        class="mt-10"
        title="Outros produtos"
        :show-price="false"
        :query="queryOutrosProdutos"
        :qtd-ofertas="20"/>
      <px-landing-page-whatsapp class="mt-5 mb-12"
                                phone-number="5548992120020"
                                :banner-cadastro-text-whats="bannerCadastroTextWhats"/>
    </v-container>
  </div>
</template>

<script>
import gridMixin from '@/mixins/grid-mixin';
import HomePageBlock from '@/modules/home-page/HomePageBlock.vue';
import LandingPageBanners from '@/modules/landing-page/LandingPageBanners.vue';
import Capa from '@/modules/landing-page/Capa.vue';
import RdDuvidaFormSubscription from '../integration/rd/RdDuvidaFormSubscription.vue';
import LandingPageWhatsapp from './LandingPageWhatsapp.vue';
import header from '../../assets/landing-page/stretch/header-plastico-filme.png';
import bannerTubete from '../../assets/landing-page/stretch/COM TUBETE.png';
import bannerTubeteMobile from '../../assets/landing-page/stretch/COM TUBETE -mobile.png';
import bannerSemTubete from '../../assets/landing-page/stretch/SEM TUBETE.png';
import bannerSemTubeteMobile from '../../assets/landing-page/stretch/SEM TUBETE -mobile.png';

export default {
  mixins: [gridMixin],
  data() {
    return {
      header,
      bannerCadastroTextWhats: 'Que tal falar com um especialista? Nos chame no Whatsapp agora!',
      query: 'disponivel is true and categoria.id = 204 order by random',
      queryOutrosProdutos: 'departamento.id = 23 and disponivel is true order by random',
      bannerCadastroText: 'Cadastre-se agora e começe a comprar os produtos que sua empresa precisa',
      banners: [],
    };
  },
  methods: {
    onClickCapaBtn() {
      document.querySelector('#container-form-cotacao')
        .scrollIntoView({ behavior: 'smooth' });
    },
  },
  created() {
    this.banners = [
      {
        foto: this.getGridImg(bannerTubete, bannerTubeteMobile),
        link: '/oferta/20513',
      },
      {
        foto: this.getGridImg(bannerSemTubete, bannerSemTubeteMobile),
        link: '/oferta/20353',
      },
    ];
  },
  components: {
    pxHomePageBlock: HomePageBlock,
    pxLandingPageBanners: LandingPageBanners,
    pxRdDuvidaFormSubscription: RdDuvidaFormSubscription,
    pxCapa: Capa,
    pxLandingPageWhatsapp: LandingPageWhatsapp,
  },
};
</script>
