<template>
  <div>
    <px-capa :banner-capa="header">
      <template v-slot:title>
        Compre plástico filme stretch para sua empresa com a Proxpect
      </template>
      <template v-slot:text>
        Cadastre-se e compre agora, sem precisar de cotações
      </template>
    </px-capa>
    <v-container class="mkt-content-container mt-5">
      <px-home-page-block
        show-link
        link="/q/c/204"
        link-target="_blank"
        class="mt-5"
        :query="query"
        title="Conheça nossos produtos"
        :qtd-ofertas="20"/>
      <px-landing-page-whatsapp class="mt-5 mb-12" phone-number="5548992120020"/>
      <px-landing-page-banners class="mt-12"
                               :banners="banners"/>
      <div class="headline font-weight-bold mt-12 ml-2 mb-5">
        Que tal fazer uma cotação agora?
      </div>
      <v-row class="mt-10">
        <v-col cols="12">
          <px-rd-duvida-form-subscription/>
        </v-col>
      </v-row>
      <px-home-page-block
        show-link
        link="/q/d/23"
        link-target="_blank"
        class="mt-10"
        :query="queryOutrosProdutos"
        title="Outros produtos"
        :qtd-ofertas="20"/>
      <px-banner-cadastro-email class="mt-10 mb-12"
                                :is-link="false"
                                :text="bannerCadastroText"
                                @click="onClickBannerCadastro"/>
      <px-vantagens class="mt-12 mb-12"/>
      <px-como-funciona class="mt-12 mb-12"/>
      <px-faq-landing-page class="mt-12 mb-10"/>
    </v-container>
  </div>
</template>

<script>
import gridMixin from '@/mixins/grid-mixin';
import HomePageBlock from '@/modules/home-page/HomePageBlock.vue';
import LandingPageBanners from '@/modules/landing-page/LandingPageBanners.vue';
import ComoFunciona from '@/modules/institucional/ComoFunciona.vue';
import Vantagens from '@/modules/institucional/Vantagens.vue';
import Capa from '@/modules/landing-page/Capa.vue';
import FaqLandingPage from '@/modules/landing-page/FaqLandingPage.vue';
import PxBannerCadastroEmail from '@/components/marketing/PxBannerCadastroEmail.vue';
import RdDuvidaFormSubscription from '../integration/rd/RdDuvidaFormSubscription.vue';
import LandingPageWhatsapp from './LandingPageWhatsapp.vue';
import header from '../../assets/landing-page/stretch/header-plastico-filme.png';
import bannerTubete from '../../assets/landing-page/stretch/com-tubete.png';
import bannerTubeteMobile from '../../assets/landing-page/stretch/com-tubete-mobile.png';
import bannerSemTubete from '../../assets/landing-page/stretch/sem-tubete.png';
import bannerSemTubeteMobile from '../../assets/landing-page/stretch/sem-tubete-mobile.png';

export default {
  mixins: [gridMixin],
  data() {
    return {
      header,
      query: 'disponivel is true and categoria.id = 204 order by random',
      queryOutrosProdutos: 'departamento.id = 23 and disponivel is true order by random',
      bannerCadastroText: 'Cadastre-se agora e começe a comprar os produtos que sua empresa precisa',
      banners: [],
    };
  },
  methods: {
    onClickBannerCadastro() {
      this.$router.push({
        name: 'public.criar-conta',
      });
    },
  },
  created() {
    this.banners = [
      {
        foto: this.getGridImg(bannerTubete, bannerTubeteMobile),
        link: '/oferta/20513',
      },
      {
        foto: this.getGridImg(bannerSemTubete, bannerSemTubeteMobile),
        link: '/oferta/20353',
      },
    ];
  },
  components: {
    pxHomePageBlock: HomePageBlock,
    pxLandingPageBanners: LandingPageBanners,
    pxRdDuvidaFormSubscription: RdDuvidaFormSubscription,
    pxComoFunciona: ComoFunciona,
    pxVantagens: Vantagens,
    pxCapa: Capa,
    pxFaqLandingPage: FaqLandingPage,
    PxBannerCadastroEmail,
    pxLandingPageWhatsapp: LandingPageWhatsapp,
  },
};
</script>
